import {
  Alert,
  Box, Divider, ListItem, ListItemAvatar, ListItemText, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";

import { models, roles } from "../../../services/enums";
import { createUserDisplayName } from "../../../services/utils";
import { MessageButton } from "../../message";
import { ModelActionButton } from "../../shared/buttons";
import { AvatarCircle } from "../../shared/other";

export default ({
  item, entry, isRole, user, plan, reload, queryArgs, canEdit = true,
}) => (
  <>
    <ListItem alignItems="flex-start">
      <ListItemAvatar style={{ marginTop: 4 }}>
        <AvatarCircle
          user={user}
          style={{
            height: 26, width: 26, marginLeft: 7, fontSize: "15px", paddingTop: "3px",
          }}
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={(
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography component="span" variant="body2">
                {createUserDisplayName(user)}
              </Typography>
            </Box>
            <Box justifyContent="flex-end" display="flex">
              {entry.isScore && canEdit && (
              <ModelActionButton
                type="add"
                buttonSize="small"
                fontSize="small"
                variant="iconbutton"
                model={models.SCORE}
                tooltip="add"
                callback={() => reload(queryArgs)}
                initData={{ entryId: entry._id, scoreUnit: entry.scoreUnit, customerUserId: user._id }}
                options={{
                  planInterval: plan?.interval, isDeadline: entry.isDeadline, isConfirmationRequired: entry.isConfirmationRequired, isTimeCap: entry.isTimeCap,
                }}
              />
              )}
              <MessageButton entryId={entry._id} userName={createUserDisplayName(user)} subordinateId={user._id} />
            </Box>
          </Box>
        )}
        secondary={(!_.isNil(item.note) && isRole(roles.COACH) && <Alert variant="outlined" severity="info" icon={false}>{item.note.content}</Alert>)}
      />
    </ListItem>
    <Divider variant="inset" component="li" />
  </>
);
